import React from 'react';
import clsx from 'clsx';

export default function Footer(props) {

    return (
        // <div className="footer-container">
        //     <img className="p-2" src="Poweredby_Conduent.svg" alt="Powered by Conduent"></img>
        // </div>
        <div className="page-footer flex-block">
            {/* print start */}
                <div className="footer-content float-left">
                    <div className="blocl-elm px-3">
                        <p className="footer-text text-center m-0">
                            &copy;2021 Conduent, Inc. All rights reserved.
                            Conduent and Conduent Agile Star are trademarks of Conduent, Inc.
                            and/or its subsidiaries in the United States and/or other countries.
                        </p>
                    </div>
                    <div align="center" className="footer-nav-items pt-2">
                        <span><a href="/PrivacyPolicy" target="_blank">Privacy Policy  </a></span>
                        {/* <span><a href="/SiteMap" target="_blank">Site Map </a></span> */}
                        <span><a href="/TermsOfUse" target="_blank">Terms of Use </a></span>
                        <span><a href="/BrowserRequirements" target="_blank">Browser Requirements  </a></span>
                        <span><a href="/AccessibilityCompliance" target="_blank">Accessibility Compliance  </a></span>
                    </div>
                </div>
                <div className="footer-brand float-left">
                         {/* <div className="poweredBy">Powered By</div> */}
                        <div className="print-logo left-bottom-brand mt-2"> <img src="Poweredby_Conduent.svg" alt="CONDUENT TM" title="CONDUENT" /> </div>
                 </div>

            {/* print end */}
        </div>
    )
}
