/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import * as RegistrationConstants from './RegistrationConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import { AppConfigDropdownActions } from '../../../SharedModule/Store/Actions/AppActions';
import { appStatusUpdateAction, downloadFileAction,downloadFileResetAction } from '../Store/Actions/RegistrationActions';
import moment from 'moment';
import Radio from "@material-ui/core/Radio";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TableComponent from '../../../SharedModule/Table/Table';
import { appPrivacyAction} from '../Store/Actions/RegistrationActions';

const headCells = [
    {
        id: 'fileName', numeric: false, disablePadding: true, label: 'File Name', enableHyperLink: true, fontSize: 12, width: '30%'
    },
    {
        id: 'description', numeric: false, disablePadding: false, label: 'Description', enableHyperLink: false, fontSize: 12
    }
];
function rand() {
    return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: '10px'
    },
}));

export default function AppPrivacyPolicy(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const registrationRef = useRef(null);
    const disabled=true;
    const anchorRef = React.useRef();
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const defaultVale = {
        "appName": "",
        "privacyPolicyInd": '',
        "privacyPolicyDetailInd": "",
        "bestPracticesInd": ""
    }
    const fetchAppDetails = appId => dispatch(appPrivacyAction(appId));

    const [values, setValues] = React.useState(defaultVale);
    const appDetails = useSelector(state => state.AppRegistrationState.appDetails);
    useEffect(() => {
         const query = new URLSearchParams(window.location.search);
         const tokenId = query.get('id')
         fetchAppDetails(tokenId);
    }, []);

    useEffect(() => {
        if(appDetails && appDetails.data){
            let appDetailsData={...appDetails.data};
            appDetailsData.bestPracticesInd= appDetails.data.bestPracticesInd === true ? 'true' : 'false';
            appDetailsData.privacyPolicyDetailInd= appDetails.data.privacyPolicyDetailInd === true ? 'true' : 'false';
            appDetailsData.privacyPolicyInd= appDetails.data.privacyPolicyInd === true ? 'true' : 'false';
            setValues(appDetailsData);
            //console.log('setValues called');
          }
        }, [appDetails]);

    return (
        <div className="block-elm" >
             <a ref={anchorRef}> </a>

            {/* <!-- main content start --> */}
            <div className="content-wrapper">


                <div className="frm-app-registration mt-1">
                    <div className="tabs-container">

                        <div className="tab-body mt-2 pb-3">
                            {/* <!-- section start --> */}
                            <div className="tabs-inner-container m-3">
                                <div className="tab-header">
                                    <h2 className="tab-heading float-left frm-label">Privacy Policy Questionnaire</h2>
                                    <div className="clearfix" />
                                </div>
                                {/* border block start */}
                                <div className="tab-body-bordered pb-3 pt-3 mt-0 mb-3">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app have a publicly available privacy policy, written in plain language, that has been affirmatively shared with the patient prior to the patient authorizing app access to their  health information?</div>


                                        <div className="form-wrapper col-sm-12 m-0 p-0">
                                            <div className="mui-custom-form col-sm-6 col-md-6 m-0 pl-3">
                                                <div className="sub-radio">
                                                    <Radio
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes1"
                                                        checked={values.privacyPolicyInd === 'true'}
                                                        disabled={disabled}
                                                    />
                                                    <label htmlFor="ShortStay-TransExclYes1" className="radio-label">
                                                        Yes
                                                </label>
                                                    <Radio
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo1"
                                                        className="ml-2"
                                                        checked={values.privacyPolicyInd === 'false'}
                                                        disabled={disabled}
                                                    />
                                                    <label htmlFor="ShortStay-TransExclNo1" className="radio-label">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                                {/* border block start */}
                                <div className="tab-body-bordered pb-3 pt-3 mt-0 mb-3">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app’s privacy policy include important information, such as, at a minimum:</div>
                                        <div className="txt-desc">
                                            <ul>
                                                <li>How a patient’s health information may be accessed, exchanged, or used by any person or other entity, including whether the patient’s health information may be shared or
                                                    sold at any time (including in the future);</li>
                                                <li>A requirement for express consent from a patient before the patient’s health information is accessed, exchanged, or used, including receiving express consent before a patient’s health information is shared or sold (other than disclosures required by law or disclosures necessary in connection with the sale of the application or a similar transaction)</li>
                                                <li>If the app will access any other information from a patient’s device; or</li>
                                                <li>How a patient can discontinue app access to their data, and what the app’s policy and process is for disposing of a patient’s data once the patient has withdrawn consent?</li>
                                            </ul>
                                        </div>

                                        <div className="form-wrapper col-sm-12 m-0 p-0">
                                            <div className="mui-custom-form col-sm-6 col-md-6 m-0 pl-3">
                                                <div className="sub-radio">
                                                    <Radio
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes2"
                                                        checked={values.privacyPolicyDetailInd === 'true'}
                                                        disabled={disabled}
                                                    />
                                                    <label htmlFor="ShortStay-TransExclYes2" className="radio-label">
                                                        Yes
                                                </label>
                                                    <Radio
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo2"
                                                        className="ml-2"
                                                        checked={values.privacyPolicyDetailInd === 'false'}
                                                        disabled={disabled}
                                                    />
                                                    <label htmlFor="ShortStay-TransExclNo2" className="radio-label">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                                {/* border block start */}
                                <div className="tab-body-bordered pb-3 pt-3 mt-0">
                                    <div className="questionnaire-area ml-3">
                                        <div className="txt-quest"><span className="MuiFormLabel-asterisk">*</span> Does the app follow industry best practices when developing a privacy policy and consult relevant resources, such as:</div>
                                        <div className="txt-desc">
                                            <ul>
                                                <li>CARIN Alliance Code of Conduct: <a title="CARIN Alliance Code of Conduct" href="https://www.carinalliance.com/our-work/trust-framework-and-code-of-conduct" target="_blank" rel="noopener noreferrer">https://www.carinalliance.com/our-work/trust-framework-and-code-of-conduct</a></li>
                                                <li>ONC Model Privacy Notice: <a title="ONC Model Privacy Notice" href="https://www.healthit.gov/topic/privacy-security-and-hipaa/model-privacy-notice-mpn" target="_blank" rel="noopener noreferrer">https://www.healthit.gov/topic/privacy-security-and-hipaa/model-privacy-notice-mpn</a></li>
                                                <li>National Institute of Standards and Technology: <a title="National Institute of Standards and Technology" href="https://www.nist.gov" target="_blank" rel="noopener noreferrer">www.nist.gov</a></li>
                                            </ul>
                                        </div>

                                        <div className="form-wrapper col-sm-12 m-0 p-0">
                                            <div className="mui-custom-form col-sm-6 col-md-6 m-0 pl-3">
                                                <div className="sub-radio">
                                                    <Radio
                                                        type="radio"
                                                        value={'true'}
                                                        id="ShortStay-TransExclYes3"
                                                        checked={values.bestPracticesInd === 'true'}
                                                        disabled={disabled}
                                                    />
                                                    <label htmlFor="ShortStay-TransExclYes3" className="radio-label">
                                                        Yes
                                                </label>
                                                    <Radio
                                                        type="radio"
                                                        value={'false'}
                                                        id="ShortStay-TransExclNo3"
                                                        className="ml-2"
                                                        checked={values.bestPracticesInd === 'false'}
                                                        disabled={disabled}
                                                    />
                                                    <label htmlFor="ShortStay-TransExclNo3" className="radio-label">
                                                        No
                                                 </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* border block end */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- main content end --> */}

        </div >
    )
}
